// Quote
// --------------------------------------------------

.quote {
	padding: 2rem;
}

.quote-text {
	margin-top: 2rem;
	font-size: $body-large-font-size;
}
.quote-author {
	margin: 3rem 0 0;
}
.quote-author-title {
	font-weight: 500;
	color: $body-secondary-color;
}
.quote-company-img {
	max-height: 2rem;
}
