.video-modal {
	position: fixed;
	z-index: $zindex-modal;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: 0;
	padding: 20px;
	text-align: center;
}

.video-modal-overlay {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	background: rgba(0,0,0,0.8);
}

.video-modal-player {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	margin: 0 auto;
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	height: 320px;
	@include media-breakpoint-up(sm) {
		width: 520px;
		height: 292px;
	}
	@include media-breakpoint-up(md) {
		width: 700px;
		height: 394px;
	}
	@include media-breakpoint-up(lg) {
		width: 940px;
		height: 528px;
	}
}
