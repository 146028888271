// Pricing table
// --------------------------------------------------

.pricing-table {
	height: 1px;

	.table-cell {
		padding: 15px;
		vertical-align: middle;
	}

	& &-cell-summary {
		vertical-align: baseline;
		padding: ($spacer * 1.5) ($spacer * 3);
		border-bottom: $table-border-width solid $table-border-color;
	}

	.pricing-plan-content {
	}

	.pricing-plan-name {
	}

	.pricing-plan-price {
		margin-bottom: $spacer / 2;
	}

	.pricing-plan-btn-wrap {
		margin: 0;
	}

	.pricing-plan-btn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		width: auto;
	}

	&-cell-plan {
		font-weight: $font-weight-bold;
		color: $teal;
		text-align: center;
		border-bottom-width: 1px !important;
		border-top: none !important;
	}

	&-cell-category {
		line-height: 33px;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		color: $dark-blue-400;
		text-align: left;
	}

	&-cell-feature {
		line-height: 1.6;
		text-align: left;
	}

	&-cell-checkmark {
		text-align: center;
	}

	&-checkmark {
		color: $primary;
		line-height: 20px;
	}
}
