// checkbox
// --------------------------------------------------

.form-check-input-styled {
	position: absolute;
	opacity: 0;

	& + label {
		position: relative;
		cursor: pointer;
	}

	& + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		background: white;
		border: 1px solid $gray-500;
	}

	&.align-right + label:before {
		margin-right: 0;
		right: 0;
		position: absolute;
	}

	&:disabled + label {
		cursor: auto;
	}

	&:disabled + label:before {
		box-shadow: none;
		background: $gray-300;
	}

	&:checked + label:after {
		content: '';
		position: absolute;
		left: 5px;
		background: $dark;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 $dark,
		4px 0 0 #121212,
		4px -2px 0 $dark,
		4px -3px 0 $dark,
		4px -4px 0 $dark,
		4px -5px 0 $dark,
		4px -6px 0 $dark,
		4px -7px 0 $dark,
		4px -8px 0 $dark;
		transform: rotate(45deg);
	}

	&.align-right:checked + label:after {
		left: auto;
		right: 13px;
	}
}

// autocomplete
// --------------------------------------------------

.input-group {
	.autocomplete-prepend,
	.autocomplete-append {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 10;
		display: flex;
		align-items: center;
		padding: 0.625rem 1rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		text-align: center
	}

	.autocomplete-append {
		right: 0;
	}

	.form-control {
		border-radius: var(--bs-border-radius-lg) !important;
		z-index: 0;
		padding-left: $input-btn-padding-x-lg * 2;
		padding-right: $input-btn-padding-x-lg * 2;
		background-color: $gray-200;
		&::placeholder {
			color: $gray-400;
		}
	}
}
