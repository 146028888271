// navbar
// --------------------------------------------------

.mobile-nav-show {
	overflow: hidden;
}

.nav {
	&-item {
		&:hover {
			.nav-link,
			.nav-link:hover {
				text-decoration: none;
				color: $light-blue;
				text-shadow: 0 0 0.5px $light-blue;
			}
		}
		&.active {
			.nav-link {
				text-decoration: none;
				color: $light-blue;
				text-shadow: 0 0 0.5px $light-blue;
			}
		}
	}
}

.sub-nav-highlighted {
	background-color: $dark-blue-300;
	padding: 4px !important;
	border-radius: 6px;
	width: fit-content;
	margin: 0 auto;
	@include media-breakpoint-up(xl) {
		margin: 24px 0 0 0;
	}
	.nav-item {
		.nav-link {
			color: $white;
			border-radius: 6px;
			padding: 9px 16px;
		}
		&.active {
			.nav-link {
				text-decoration: none;
				background-color: $dark-blue;
			}
		}
	}
}

.navbar-toggler {
	padding: 0;
	z-index: $zindex-modal;
	margin-left: auto;
	display: flex;
	align-items: center;
	&:focus {
		outline: none;
	}
	&:not(.collapsed) {
		.navbar-toggler-icon {
			border-color: transparent;
			&:before {
				@include rotate(45deg);
				top: -1px;
				background-color: $dark-blue;
			}
			&:after {
				@include rotate(-45deg);
				bottom: 5px;
				background-color: $dark-blue;
			}
		}
	}
	&-icon {
		@include transition(all 0.3s ease);
		border-top: 3px solid $light;
		height: 10px;
		width: 24px;
		box-sizing: border-box;
		position: relative;
		right: 0;
		top: 0;
		cursor: pointer;
		&:before, &:after {
			@include transition(all 0.3s ease);
			content: "";
			display: block;
			height: 3px;
			width: 24px;
			position: absolute;
			left: 0;
			background-color: $light;
		}
		&:before {
			top: -10px;
		}
		&:after {
			bottom: 0;
		}
	}
}

//  Fixed nav on scrolling for Desktop
// --------------------------------------------------

.navbar {
	padding: 0;
	background-color: $main-nav-bg;
	z-index: $zindex-nav;
	width: 100%;
	top: 0;
	&:has(.navbar-collapse.show) {
		background-color: $main-nav-bg-mobile;
		transition: background-color 0.3s ease-in-out;
	}
}

@include media-breakpoint-up(xl) {


	.nav-item {
		display: flex;
		align-items: center;
	}
	.dropdown {
		position: static;
		.nav-link {
			cursor: default;
		}
	}
	.navbar-main {
		.dropdown-menu {
			max-width: 100%;
			min-width: 1140px;
		}
	}

	.nav-wrapper {
		background-color: #fff;
		z-index: 1100;
		width: 100%;
		top: 0;
	}

	.nav-sticky {
		position: fixed;
		top: -($main-nav-height + $top-nav-height + $nav-border-bottom-width);
		background-color: $white;
		@include box-shadow(0 $nav-border-bottom-width 0 $border-color);
	}
	.nav-shown,
	.nav-hidden {
		@include transition(all 0.3s ease);
	}
	.nav-hidden {
		top: -($main-nav-height + $top-nav-height + $nav-border-bottom-width);
	}
	.nav-shown {
		top: 0;
	}
}


// Sub Nav
// --------------------------------------------------

.sub-nav {
	padding: 20px;
	font-size: $font-size-xs;
	@include media-breakpoint-up(xl) {
		font-size: $font-size-action;
	}
}


// Top Nav
// --------------------------------------------------

.navbar-top {
	display: flex;
	flex-direction: row;
	background-color: $main-nav-bg;
	.main-nav-dropdown-link-with-shadow {
		margin-left: 0;
	}
}

.top-nav {
	padding: 15px 0px;
	z-index: $zindex-top-nav;
	font-size: $font-size-xs;
	height: $top-nav-height;
	@include media-breakpoint-up(xl) {
		font-size: $font-size-action;
		padding: 15px 20px;
	}

	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}

	.navbar-border {
		border-bottom: none;		
		@include media-breakpoint-up(xl) {
			border-bottom: 1px solid $dark-blue-200;
		}
	}

	.navbar-separator {
		border-right: 1px solid $dark-blue-200;
	}

	.nav-link {
		font-size: $h7-font-size;
		&.nav-link-alt-color {
			color: $light-blue;
		}	
	}

	.navbar-top {
		height: 30px;
		justify-content: flex-end;
		.nav-item:nth-last-child(2) {
			border-left: 1px solid $dark-blue-200;
		  }
		.nav-item,
		.nav-link {
			display: flex;
			align-items: center;
			font-size: $font-size-xxs;
			white-space: nowrap;
			color: $white;
			cursor: pointer;
			&:hover {
				.nav-link,
				.nav-link:hover {
					color: $white;
					text-shadow: 0 0 0.5px $white;
				}
			}
			&.active {
				.nav-link {
					color: $white;
					text-shadow: 0 0 0.5px $white;
				}
			}

			.dropdown-toggle {
				cursor: pointer;
			}

			.dropdown-toggle::after {
				display: none !important;
			}

		}
		.nav-item {
			&.dropdown {
				position: relative;
			}
		}
		.nav-link {
			padding: .2rem .5rem;
		}

	}

	.main-nav-dropdown {
		position: absolute;
		width: auto;
		padding: 15px 0 0;
		left: 0;
		top: 100%;
		right: auto;
		background-color: $light;
		border-radius: 8px;
		transform: translateX(-50%);
	}
}

.top-nav-link-icon {	
	color: $light;
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	width: 20px;
	font-size: 20px;
	&.icon {
		font-size: 20px;
		line-height: 1;
		vertical-align: middle;
	}
}