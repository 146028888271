// Headings
// -------------------------

[data-bs-theme=light] {
	--#{$prefix}heading-color: #{$body-color};
}

[data-bs-theme=dark] {
	--#{$prefix}heading-color: #{$body-color-dark};
}

h6, h5, h4, h3, h2, h1 {
  color: var(--#{$prefix}heading-color);
}

//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes

@each $color, $value in $theme-colors-bg-subtle {
	.blockquote-text-bg-#{$color} {
		background-color: var(--#{$prefix}#{$color}-bg-subtle) if($enable-important-utilities, !important, null);
		border-left-width: .25rem;
		border-left-style: solid;
		border-left-color: var(--#{$prefix}#{$color}) if($enable-important-utilities, !important, null);
	}
}


.blockquote {
  margin-bottom: $blockquote-margin-y;
	color: $body-color;
	@include font-size($blockquote-font-size);

	> :last-child {
		margin-bottom: 0;
	}
}

.blockquote-footer {
	margin-top: $blockquote-margin-y;
	margin-bottom: 0;
	@include font-size($blockquote-footer-font-size);
	color: $blockquote-footer-color;

	&::before {
		content: "";
	}
}

