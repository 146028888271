// Blog
// --------------------------------------------------


// info

.blog-info {
	margin: 10px 0 20px 0;
	font-size: $font-size-xs;
}

.blog-info-category {
	color: $teal;
	&:focus {
		color: $dark-blue-400;
		text-decoration: underline;
	}
}

.blog-info-date {
	color: $text-muted;
	margin-left: 10px;
}


// title

.blog-title {
	font-size: $h1-font-size;
	text-align: left;
	margin: 10px 0 40px 0;
	@media (min-width: 992px) {
		font-size: $h1-font-size;
	}
}


// image

.blog-image {
	@include img-fluid();
	display: block;
	margin: 40px auto;
}


// author

.blog-author {
	display: flex;
	align-items: flex-start;
	margin-bottom: 40px;
}

.blog-author-avatar {
	width: 60px;
	height: 60px;
	margin-right: 15px;
	border-radius: 30px;
	font-size: 10px;
}

.blog-author-text {
	flex: 1;
	margin-top: 8px;
}

.blog-author-text-name {
	font-size: $font-size-sm;
	color: $teal;
	margin: 0;
	font-weight: $font-weight-bold;
}

.blog-author-text-title {
	font-size: $font-size-sm;
	color: $body-color;
	margin: 0;
}


// content

.blog-content {
	line-height: 1.6;
	margin: 0 0 40px 0;
	.rich-text {
		p {
			margin-bottom: 30px;
		}
		h1, h2, h3, .h1, .h2, .h3 {
			margin-top: 40px;
			margin-bottom: 30px;
			&:first-child {
				margin-top: 0;
			}
		}

		h4, h5, h6, .h4, .h5, .h6 {
			margin-top: 30px;
			margin-bottom: 20px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.blog-content-image {
	margin: 10px 0 50px 0;
}


// share

.blog-share {
	margin: 40px 0 80px 0;
}

.blog-share-text {
	display: inline-block;
	vertical-align: middle;
	font-size: $font-size-tiny;
	color: $text-muted;
	text-transform: uppercase;
	margin-right: 8px;
	letter-spacing: 1px;
}

.blog-share-icon {
	display: inline-block;
	vertical-align: middle;
    text-align: center;
    width: 25px;
    height: 24px;
    font-size: 22px;
    line-height: 22px;
	color: $text-muted;
	padding: 0;
	margin: 0 5px;
	&:focus {
		color: $dark-blue-400;
		text-decoration: none;
	}
}


// includes

.blog-post {
	text-align: left;
	margin-bottom: $spacer * 2;
	&-body {
		margin: ($spacer * 2) 0 0
	}
	&-title {
		margin-bottom: $spacer * .75;
	}
	&-link {
		color: $dark-blue-400;
		&:focus {
			text-decoration: none;
			color: $teal;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	&-horizontal {
		display: flex;
		align-items: flex-start;
		margin-bottom: 20px;
		&-body {
			flex: 1;
		}
	}
	&-tags {
		line-height: 1;
	}
}

// Pagination
// --------------------------------------------------

.pagination {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  gap: 4px;
  margin: 2rem 0;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    gap: 8px;
  }

  .page-item {
    display: flex;
    margin: 0;
    
    .page-link {
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-200;
      color: $gray-600;
      font-size: $font-size-sm;
      background-color: white;
      transition: all 0.2s ease;
      text-decoration: none;

      @media (min-width: 768px) {
        width: 40px;
        height: 40px;
        font-size: $font-size-base;
      }

      &:hover {
        background-color: $gray-100;
        border-color: $gray-300;
        color: $dark-blue;
      }

      span[aria-hidden="true"] {
        font-size: 16px;
        line-height: 1;

        @media (min-width: 768px) {
          font-size: 18px;
        }
      }
    }

    &:not(.active):not(:first-child):not(:last-child):not(.disabled) {
      @media (max-width: 767px) {
        &.hide-on-mobile {
          display: none;
        }
      }
    }

    &.active {
      .page-link {
        background-color: $dark-blue;
        color: white;
        border: none;
        
        &:hover {
          background-color: $dark-blue;
        }
      }
    }

    &.disabled {
      .page-link {
        background-color: $gray-100;
        border-color: $gray-200;
        color: $gray-400;
        cursor: not-allowed;

        &:hover {
          background-color: $gray-100;
          border-color: $gray-200;
        }
      }
    }
  }
}
