// Pricing plan
// --------------------------------------------------

.pricing-plan-btn-wrap {
	margin: 2.5rem 0;
}

.pricing-plan-btn {
	padding-top: 10px;
	padding-bottom: 10px;
	white-space: nowrap;
	width: 180px;
	max-width: 100%;
}

.pricing-plan-extends {
	font-size: 15px;
	color: $dark-blue-400;
	font-weight: $font-weight-bold;
	text-align: left;
}

.pricing-plan-features {
	margin: 0;
	padding: 0;
	list-style: none;
}
