//
// Badge
//

$theme-colors-text-badge: (
	"primary": $light,
	"success": $dark-blue,
	"secondary": $gray-500,
	"blue": $light-blue-400,
	"light_blue": $light-blue-400,
	"dark_blue": $light,
	"green": $green-400,
	"light_green": $green-400,
	"dark_green": $light,
	"turquoise": $turquoise-400,
	"light_turquoise": $turquoise-400,
	"dark_turquoise": $light-bg-subtle,
);

:root {
	@each $color, $value in $theme-colors-text-badge {
		--#{$prefix}#{$color}-text-badge: #{$value};
	}
}

@each $color, $value in $theme-colors-bg-subtle {
	.badge-text-bg-#{$color} {
		color: var(--#{$prefix}#{$color}-text-badge) if($enable-important-utilities, !important, null);
		background-color: var(--#{$prefix}#{$color}-bg-subtle-darker) if($enable-important-utilities, !important, null);
	}
}

.badge {
  white-space: unset !important;
}
