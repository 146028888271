// lazy loading
// --------------------------------------------------

// all images have another image right beside it, inside a noscript tag, that will be shown instead in case of no js. More details: https://github.com/aFarkas/lazysizes#the-noscript-pattern
.no-js img.lazyload {
	display: none;
}

img.lazyload:not([src]) {
	visibility: hidden;
}

.hero {
	.unknown-ratio-container::before {
		padding-top: 0;
	}
}

// tooltip
// --------------------------------------------------

.tooltip-inner {
	max-width: 260px;
	a {
		color: #FFFFFF;
		text-decoration: underline;
	}
}

.pricing-tooltip {
    --bs-tooltip-bg: linear-gradient(151.97deg, #010D40 13.65%, #02215B 82.63%);
    background: var(--bs-tooltip-bg);
    --bs-tooltip-color: $white;
    --bs-tooltip-border-radius: 0.375rem;
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    
    --bs-tooltip-border: none;
    border-radius: var(--bs-tooltip-border-radius);
    
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    &.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
    &.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
    &.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: #010D40;
        opacity: 0.9;
    }
}

.datatypes-tooltip {
	--bs-tooltip-bg: linear-gradient(151.97deg, #010D40 13.65%, #02215B 82.63%);
    background: var(--bs-tooltip-bg);
    --bs-tooltip-color: $white;
    --bs-tooltip-border-radius: 0.375rem;
    --bs-tooltip-opacity: 0.9;

    border-radius: var(--bs-tooltip-border-radius);

	--bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    &.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
    &.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
    &.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: #010D40;
        opacity: 0.9;
    }
    &.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: #010D40;
        opacity: 0.9;
    }

	i {
		color: $gray-400;
		background-color: transparent;
		height: 100%;
		font-size: inherit;
		line-height: inherit;
	}
}

// Horizontal rule
// --------------------------------------------------

[data-bs-theme="dark"] {
	--#{$prefix}hr-color: #{$dark-blue-200};
}

[data-bs-theme="light"] {
	--#{$prefix}hr-color: #{$gray-300};
}

hr {
	color: var(--#{$prefix}hr-color);
	opacity: 1;
}

// Vertical rule
// --------------------------------------------------

.vr {
	opacity: 1;
}

// Social media links
// --------------------------------------------------

.social-link {
	padding: .75rem;
}

.social-link-image {
	width: 2.4rem;
	height: 2.4rem;
}

.cursor-pointer {
	cursor: pointer;
}


