// logo
// --------------------------------------------------

.main-logo {
	display: inline-block;
	margin: 0;
	height: $main-logo-height-mobile;
	z-index: 1500;
	@include media-breakpoint-up(xl) {
		height: $main-logo-height
	}
}

.main-logo-img {
	display: inline-block;
	height: $main-logo-height-mobile;
	@include media-breakpoint-up(xl) {
		height: $main-logo-height
	}

}


// main nav
// --------------------------------------------------

.main-nav {
	padding: 20px 8px 20px 8px;
	font-size: $font-size-xs;

	@include media-breakpoint-up(xl) {
		height: $main-nav-height;
		font-size: $font-size-action;
	}

}

// nav links size, nav links color
.main-nav .nav-link {
	margin: 0 ($spacer / 6);
	color: $main-nav-text-color;
	&:hover, &:focus, &:active {
		color: darken($main-nav-text-color, 15%);
		text-decoration: none;
	}

	@include media-breakpoint-up(xl) {
		margin: 0 ($spacer / 2);
	}
}

// main nav dropdown
// --------------------------------------------------

$main-nav-dropdown-link-padding: 4px 10px;
$main-nav-tick-size: 10px;

.navbar-nav .dropdown-menu {
	margin: 0;
	padding: 16px 0 0;
	border: none;
	top: 100%;
	left: auto;
	background-color: transparent;
}

.navbar-nav .dropdown-menu-body {
	background-color: #FFFFFF;
	border-radius: 8px;
	display: flex;
}

.fill-width.dropdown {
	position: static;
}
.fill-width.dropdown > .dropdown-menu {
	left: auto;
	right: 6px;
	
	&:has(.simple-dropdown-menu) {
		right: auto;
	}

	.main-nav-dropdown-link {
		white-space: normal;
	}

	@include media-breakpoint-up(xxl) {
		right: 8%;
	}
}

.main-nav-dropdown-caption {
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: #7f999f;
	font-size: $font-size-tiny;
}

.dropdown-toggle::after {
	transition: transform 0.2s;

}


//use chevron as dropdown toggle icon on mobile view
.dropdown-icon {
	display: none;
  }

@include media-breakpoint-down(xl) {	
	.dropdown-icon {
		display: inline-block;
		margin-left: 0.5em; 
		transition: transform 0.3s ease; 
	  }
	  
	  .navbar-nav .show > .dropdown-icon {
		transform: rotate(90deg);
	  }
	
	.navbar-nav .dropdown-toggle::after {
		display: none !important;
	  }
}

.nav-item {
	&.show {
		.dropdown-toggle::after {
			@include rotate(180deg);
		}
	}
	&:hover, &:focus, &:active {
		.dropdown-toggle::after {
			@include rotate(180deg);
		}
	}
}

.link-subtitle,
.link-title {
	.main-nav-dropdown-text {
		font-size: $title-font-size;
		padding-bottom: 1rem;
	}
}

//main menu main titles
.link-title {
	.main-nav-dropdown-text {
		color: $dark-blue;
		font-weight: $font-weight-bolder;
		&:hover {
			color: $dark-blue !important;
		}
	}
}
//main menu sub titles(headers for lists of links
.link-subtitle {
	.main-nav-dropdown-text {
		color: $gray-400;
		font-size: $body-small-font-size;
		font-weight: $font-weight-normal;		
		&:hover {
			color: $gray-400 !important;
		}
	}
}
.link-highlight {
	background-color: rgba(202, 219, 252, 0.4);
	box-shadow: 1px 2px 10px 0 rgba(121, 133, 149, 0.4);
	border-radius: 4px;
	&.dropdown {
		.nav-link {
			padding-left: 0.75rem;
		}
	}
	&.show {
		.dropdown-toggle::after {
			@include rotate(90deg);
		}
	}
	.dropdown-toggle::after {
		border: none;
		font-family: "plectoicons";
		content: "\ea36";
		vertical-align: 0;
		transition: transform 0.1s;
		text-decoration: none;
	}
	&:hover, &:focus, &:active {
		.dropdown-toggle::after {
			@include rotate(90deg);
		}
	}
}

.link-cta {
	padding-bottom: 1rem;
	text-decoration: none !important;
}

.main-nav-dropdown-description {
	display: block;
	text-transform: none;
	color: $text-muted;
	font-size: $font-size-xxs;
	font-weight: $font-weight-normal;
	text-decoration: none;
	white-space: normal;
}

.main-nav-dropdown-text,
.main-nav-dropdown-link {
	white-space: nowrap;
	display: flex;
	gap: 8px;
	align-items: center;
	color: $dark-blue-200;
	font-size: $font-size-action;
	text-decoration: none;
	&:hover {
		color: $light-blue;
	}
	&.active {
		color: $light-blue;
	}
}

.main-nav-dropdown-link {
	padding: 0.5rem 0;
	cursor: pointer;
	font-weight: $font-weight-normal;
	&:focus {
		text-decoration: none;
		color:  $light-blue;
		text-shadow: 0 0 0.5px  $light-blue; /* "bold" font effect, it prevents content from jumping */
	}
}

.main-nav-dropdown-link-icon {	
	color: $light-blue;
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	width: 20px;
	font-size: 20px;
	&.icon {
		font-size: 20px;
		line-height: 1;
		vertical-align: middle;
	}
	@include media-breakpoint-down(xl) {        
		width: 16px;
		&.icon {
			font-size: 16px;
		}
	}
}

//TODO: first check if we want this to be used at all - if then maybe change padding for mobile view
.main-nav-dropdown-link-with-shadow {
	margin-left: -20px;
	display: flex;
	gap: 8px;
	position: relative;
	font-weight: $font-weight-normal;
	padding: 15px 30px 15px 20px;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: $light-blue
	}
	&.active {
		.main-nav-dropdown-text {
			color: $light-blue
		}
	}

	&:focus {
		text-decoration: none;
		color:  $light-blue;
		border-radius: 8px;
		background-color: rgba(243, 244, 246, 0.4);
		box-shadow: 1px 2px 10px 0 rgba(121, 133, 149, 0.4);
		.main-nav-dropdown-link {
			text-shadow: 0 0 0.5px  $light-blue; /* "bold" font effect, it prevents content from jumping */
		}
	}
	&:after {
		text-decoration: none;
		color:  $light-blue;
		font-family: "plectoicons";
		position: absolute;
		right: 10px;
		left: auto;
		top: 50%;
		transform: translateY(-50%);
		font-size: 22px;
	}
}

// grouped dropdown
.main-nav-dropdown-grouped {
	padding: 0;
}

.main-nav-dropdown-group-container {
	display: flex;
	padding: 1.5rem;
	border-radius: 8px;

}

.main-nav-dropdown-group {
	padding: 0 20px;
	margin: 0 -20px;
	background-color: $white;
	border-radius: 8px;
	.main-nav-dropdown-link-icon {
		color: $light-blue;
	}

}

.main-nav-dropdown-group-body {
	display: flex;
	flex-direction: column;
	flex: 1 auto;
}

.main-nav-dropdown-group-odd {
	padding: 20px;
}

.main-nav-dropdown-group-even {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-nav {
	&.navbar-main {
		gap: 10px;
		@include media-breakpoint-down(xl) {
			overflow: auto;
			
		}
	}
}

// main nav dropdown on hover - Desktop only
// --------------------------------------------------
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}  

@include media-breakpoint-up(xl) {
	.navbar-collapse {
		flex: 1 auto;
	}
	.navbar-nav {
		align-items: stretch;
		margin-right: 80px;
	}
	.navbar-nav .dropdown:hover > .dropdown-menu {
		display: block;
		animation: fadeIn 0.2s ease-in;
	}
	.navbar-nav .dropdown > .dropdown-menu {
		display: none;
	}
	.navbar-nav .dropdown.show > .dropdown-menu {
		display: none;
	}
	.main-nav-dropdown-group-col {
		&:first-child {
			padding-right: 20px;
		}
	}
	.main-nav-dropdown-group {
		margin: 0;
		//padding: 40px 30px 40px 30px;
	}
	.main-nav-dropdown-group-odd {
		flex: 0 0 260px;
	}
	.main-nav-dropdown-group-even {
		flex: 1 1 auto;
	}
	.main-nav-dropdown-group-body {
		gap: 10px;
	}
	.main-nav-dropdown-caption {
		gap: 10px;
	}
}


//Options for "background color" in menu items {% if child.background_color %} main-nav-dropdown-group-{{ child.background_color }}{% endif %}
//CMS option light-gray  
.main-nav-dropdown-group-light-grey  {
	background-color: $gray-200;
	border-radius: 8px;
	.main-nav-dropdown-link-with-shadow {
		&:focus {
			background: $white;
		}

		.main-nav-dropdown-text {
			&:hover {
				background: $gray-200;
				color: $light-blue;
			}
			&.active {
				color: $light-blue;
			}
			
		}

		.main-nav-dropdown-link-icon {
			color: $light-blue !important;
		}
	}
	.main-nav-dropdown-link-icon {
		font-size: 20px;
		color: $gray-200;
		align-self: flex-start;
		@include media-breakpoint-down(xl) {
			background-color: $light-blue;			
		}
	}

	//reset background color to white on mobile
	@include media-breakpoint-down(xl) {
		background-color: $light;
	}
}

//CMS option light-blue 
.main-nav-dropdown-group-light-blue  {
	background-color: $light;
	border-radius: 8px;
	.main-nav-dropdown-link-with-shadow {
		&:focus {
			background: $white;
		}
		&:hover {
			color: $light-blue;
		}
		&.active {
			.main-nav-dropdown-text {
			color: $light-blue;
			}
		}

		.main-nav-dropdown-text {
			&:hover {
				color: $light-blue;
			}
		}

		.main-nav-dropdown-link-icon {
			color:  $light-blue !important;
		}
	}
	.main-nav-dropdown-link-icon {
		font-size: 20px;
		color: $light-blue;
		align-self: flex-start;		
		@include media-breakpoint-down(xl) {
			color: $light-blue;
			
		}
	}

	@include media-breakpoint-down(xl) {
		background-color: $light;
	}
	
}

//CMS option light-turquoise 
.main-nav-dropdown-group-light-turquoise  {
	background-color: $turquoise-200;
	border-radius: 8px;
	.main-nav-dropdown-link-with-shadow {
		&:focus {
			background: $white;
		}
		&:hover {
			color: $turquoise;
			text-decoration: none;
		}
		&.active {
			.main-nav-dropdown-text {
			color: $turquoise;
			}
		}

		.main-nav-dropdown-text {
			&:hover {
				color: $turquoise;
			}
		}

		.main-nav-dropdown-link-icon {
			color: $turquoise !important;
		}
	}
	.main-nav-dropdown-link-icon {
		font-size: 20px;
		color: $turquoise;
		align-self: flex-start;
		@include media-breakpoint-down(xl) {
			color: $light-blue;			
		}
	}

	//no bg color on mobile menu
	@include media-breakpoint-down(xl) {
		background-color: $light;
	}
}

.main-nav-dropdown-group-col {
	padding-bottom: 20px;
}

@include media-breakpoint-up(xl) {
	.navbar-wrapper {
		position: relative;
	}
}

// mobile nav
// --------------------------------------------------

@include media-breakpoint-down(xl) {

	.main-nav {
		.nav-link {
			color: $main-nav-text-color-mobile;
		}
	}
	.nav {
		&-close {
			height: 32px;
			width: 32px;
			position: absolute;
			top: 25px;
			right: 25px;
		}
	}
	.navbar {
		&-toggler-wrapper {
			padding: 20px;
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		&-wrapper {
			@include transition(margin .4s ease);
			overflow: hidden;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			left: auto;
			margin-right: -100%;
			bottom: 0;
			width: 100%;
			background-color: $white;
			padding-top: 120px;
			display: flex;
			flex-direction: column;
		}

		&-nav {
			&-expanded {
				@include box-shadow(inset 0 0 5px rgba(0, 0, 0, 0.075));
			}
		}

		&-collapse {
			min-height: 100%;
			position: fixed;
			z-index: $zindex-fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;

			&:before {
				@include transition(opacity .4s ease-in-out);
				opacity: 0;
				background-color: rgba(0, 0, 0, 0.4);
				content: "";
				position: fixed;
			}

			&.show {
				.navbar-wrapper {
					margin-right: 0;
				}

				&:before {
					opacity: 1;
					z-index: 0;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}

			.btn {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}

	.main-nav {
		&-dropdown {
			padding: 5px 0 0;
			box-shadow: none;
			left: auto;
			transform: none;
			background-color: transparent;

			&::before,
			&::after {
				display: none;
			}

			&-group {
				&-container {
					display: flex;
					padding: 0;
				}

				&-light {
					border: none;
					padding-top: $spacer / 2;
				}

				&-caption:first-child {
					padding-top: 0;
				}
			}

			&-link {
				font-size: $font-size-xs;
				font-weight: $font-weight-normal;
			}

			&-description {
				display: none;
			}
		}

		.nav {
			&-item {
				white-space: nowrap;
				border-bottom: 1px solid $gray-100;
				padding: 0 20px;

				&:first-child {
					border-top: 1px solid $gray-100;
				}

				&.dropdown {
					&.show {
						.dropdown-toggle {
							&::after {
								@include rotate(180deg);
							}
						}
					}
				}
			}

			&-link {
				padding: ($spacer * 0.75) 0;
				margin: 0;
				font-size: 16px;
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				&:hover,
				&:active {
					outline: none;
					color: $dark-blue-400;
				}
			}
		}
	}

	// Fix for overlying mobile nav by the icon
	.hide-hubspot {
		z-index: 999 !important;
	}
}

// Case study
.case-study {
	min-width: 400px;
	height: 150px;
	border-radius: 8px;
	margin-right: 10px;

	.case-study-category {
		font-size: 12px;
		white-space: normal;
		color: rgba(2, 23, 51, 0.4);
	}
	.case-study-title {
		font-size: $font-size-action;
		color: #021733;
		white-space: normal;
	}
	.case-study-excerpt {
		font-size: $font-size-tiny;
		color: #021733;
		overflow: hidden;
		white-space: normal;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		height: auto;
		min-height: 50%;
		margin-bottom: 0px !important;
	}
	.case-study-customer-logo {
		position: absolute;
		bottom: 10px;
		left: 10px;
		right: auto;
		top: auto;
		background-color: rgba(255, 255, 255, 0.85);
		padding: 10px;
		border-radius: 4px;
	}

	.case-study-image {
		width: 170px;
		min-width: 170px;
		height: 150px;
		object-fit: cover;
		object-position: center;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
		@include media-breakpoint-down(xl) {
			height: auto;
			max-height: 240px;
			width: 100%;
			margin-right: 0px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}


	@include media-breakpoint-down(xl) {
		height: 100%;
		min-height: 240px;
		width: 100%;
		min-width: auto;
		margin-right: 0px;
	}

}

// Update mobile navigation for Bootstrap 5
@include media-breakpoint-down(xl) {

	.main-logo-img {
		transition: opacity 0.3s ease;
		opacity: 1;
		
		&.d-none {
			opacity: 0;
		}
	}
	.navbar {
		&-toggler {
			padding: 0.25rem 0rem;
			border: 0;
			
			&:focus {
				box-shadow: none;
			}
		}

		&-collapse {
			position: fixed;
			z-index: var(--bs-zindex-fixed);
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.4);
			padding-right: 0;
			transform: translateX(100%);
			transition: transform 0.1s ease-in-out;
			max-width: 100vw;
			
			&.show {
				transform: translateX(0);
				
				.navbar-wrapper {
					transform: translateX(0);
				}
			}
		}
		
		&-wrapper {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			background: $white;
			transform: translateX(100%);
			transition: transform 0.1s ease-in-out;
			padding-top: 120px;
			overflow-y: auto;
		}
	}
}

.dropdown-toggle {
  &::after {
    transition: transform 0.2s;
  }
}

.nav-item {
  &.show {
    .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }
}


