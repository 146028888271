//
// Button Group
//

.btn-group {
	padding: .25rem;
	background-color: $gray-200;
	border: 1px solid $gray-300;
	border-radius: var(--#{$prefix}border-radius);

	> .btn:hover,
	> .btn.active {
		color: $light;
		background-color: $dark-blue;
		border-color: $dark-blue;
	}
}
