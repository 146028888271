// dropdown
// --------------------------------------------------

.dropdown {
	&-menu {
		--bs-dropdown-padding-y: 0.5rem;
		--bs-dropdown-padding-x: 0;
		
		&-outline {
			border: 1px solid $gray-500;
		}
	}
	&-header {
		padding: 0 10px 10px;
	}
	&-body {
		height: auto;
		max-height: 17rem;
		min-width: 280px;
		overflow-x: hidden;
		margin-right: 4px;
		margin-bottom: 4px;
		.dropdown-item {
			position: relative;
			padding: ($spacer / 1.5) ($spacer / 1.25);
			&:active {
				background-color: var(--bs-dropdown-link-active-bg);
				color: var(--bs-dropdown-link-active-color);
			}
		}
	}
	&-footer {
		border-top: 1px solid $gray-300;
		padding: 18px 20px 10px 10px;
	}

	// Scrollbar
	// --------------------------------------------------

	/* width */
	& ::-webkit-scrollbar {
		width: 5px;
		background-color: transparent;
	}

	/* Track */
	& ::-webkit-scrollbar-track {
		background-color: transparent;
	}

	/* Handle */
	& ::-webkit-scrollbar-thumb {
		background-color: $gray-700;
		border-radius: 10px;
		height: $spacer * 2;
	}

}
