//
// Buttons
//

[data-bs-theme="light"] {
	@each $color, $value in $theme-colors {
		&.section-#{$color},
		&.card-#{$color} {
			.link-primary {
				--#{$prefix}primary-rgb: #{$value};
				--#{$prefix}link-hover-color-rgb: #{if($color == "light", $dark-blue, $value)};
				&:hover,
				&:focus {
					color: var(--#{$prefix}link-hover-color-rgb) if($enable-important-utilities, !important, null);
				}
			}
			.btn-outline-primary,
			.btn-primary {
				--#{$prefix}btn-hover-bg: #{if($color == "light", $light_blue, $value)};
				--#{$prefix}btn-hover-border-color: #{if($color == "light", $light_blue, $value)};
			}
		}
	}
}

[data-bs-theme="dark"] {
	@each $color, $value in $theme-colors {
		&.section-#{$color},
		&.card-#{$color} {
			.link-primary {
				--#{$prefix}primary-rgb: #{$value};
				--#{$prefix}link-hover-color-rgb: #{$value};
				&:hover,
				&:focus {
					color: var(--#{$prefix}link-hover-color-rgb) if($enable-important-utilities, !important, null);
				}
			}

			.btn-outline-primary {
				--#{$prefix}btn-color: #{$light};
				--#{$prefix}btn-border-color: #{$light};

				--#{$prefix}btn-hover-bg: #{$value};
				--#{$prefix}btn-hover-border-color: #{$value};
			}

			.btn-primary {
				--#{$prefix}btn-color: #{$primary};
				--#{$prefix}btn-bg: #{$light};
				--#{$prefix}btn-border-color: #{$light};

				--#{$prefix}btn-hover-bg: #{$value};
				--#{$prefix}btn-hover-border-color: #{$value};
			}

		}
	}

}

a {
	display: inline-flex;
	gap: $icon-link-gap;
	align-items: center;
	backface-visibility: hidden;
	text-decoration: none;

	& > .icon {
		font-size: 0.75rem;
		flex-shrink: 0;
		text-align: center;
		width: $icon-link-icon-size;
	}
}
