// Accordion
// --------------------------------------------------

.accordion-button {
	font-weight: 500;
	font-size: 1.25rem;
}

.accordion-item {
	border-top: none;
	border-left: none;
	border-right: none;
}

.section[data-bs-theme="dark"] {
	.accordion {
	  // scss-docs-start accordion-css-vars
	  --#{$prefix}accordion-color: #{$body-color-dark};
	  --#{$prefix}accordion-border-color: #{$dark-blue-200};
	  --#{$prefix}accordion-btn-color: #{$body-color-dark};
	  --#{$prefix}accordion-active-color: #{$body-color-dark};
	  // scss-docs-end accordion-css-vars
	}
}
