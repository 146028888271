// Loader
// --------------------------------------------------

@keyframes loader-spin {
	to {
		transform: rotate(360deg);
	}
}

.loader {
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: text-bottom;
	border: solid 2px $dark;
	border-color: currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: loader-spin 800ms linear infinite;
}

.loader-sm {
	width: 12px;
	height: 12px;
	border-width: 2px;
}

.loader-lg {
	width: 40px;
	height: 40px;
	border-width: 4px;
}

.loader-xl {
	width: 80px;
	height: 80px;
	border-width: 5px;
}


.btn .loader {
	margin: -2px 5px 0 5px;
	vertical-align: middle;
}
