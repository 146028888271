:root {
	@each $color, $value in $theme-colors-text-subtle {
		--#{$prefix}#{$color}-text-subtle: #{$value};
	}
	@each $color, $value in $theme-colors-bg-section {
		--#{$prefix}#{$color}-bg-section: #{$value};
	}
	@each $color, $value in $theme-colors-bg-subtle-darker {
		--#{$prefix}#{$color}-bg-subtle-darker: #{$value};
	}
	@each $color, $value in $theme-colors-bg-subtle {
		--#{$prefix}#{$color}-bg-subtle: #{$value};
	}
}

@each $color, $value in $theme-colors-text {
  .highlight-#{$color} {
    color: var(--#{$prefix}#{$color}) if($enable-important-utilities, !important, null);
  }
}

body[data-bs-theme="dark"] {
	background-image: $gradient;
}


@each $color, $value in $theme-colors-bg-subtle {
	.bg-#{$color}-subtle {
		background-color: var(--#{$prefix}#{$color}-bg-subtle) if($enable-important-utilities, !important, null);
	}
}

@each $color, $value in $theme-colors-bg-subtle-darker {
	.bg-#{$color}-subtle-darker {
		background-color: var(--#{$prefix}#{$color}-bg-subtle-darker) if($enable-important-utilities, !important, null);
	}
}


