// Fonts
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300..900&display=swap');

// Bootstrap
// -------------------------------------------

// Bootstrap overrides
@import "variables";
@import "variables-dark";
@import "overrides/root";
@import "mixins";

// Bootstrap required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Bootstrap base
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/utilities/api";

// Bootstrap components
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/forms/form-check";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/navbar";

// Bootstrap overrides
@import "overrides/accordion";
@import "overrides/badge";
@import "overrides/buttons";
@import "overrides/button_group";
@import "overrides/breadcrumb";
@import "overrides/card";
@import "overrides/form";
@import "overrides/nav";
@import "overrides/tables";
@import "overrides/type";
@import "overrides/dropdown";
@import "overrides/navbar";
@import "overrides/stretched-link";

// Swiper
// -------------------------------------------
@import 'node_modules/swiper/swiper';
@import "overrides/swiper/swiper";
@import 'node_modules/swiper/modules/navigation';
@import 'node_modules/swiper/modules/pagination';
@import "overrides/swiper/pagination";
@import "overrides/swiper/navigation";


// Custom styles
// -------------------------------------------

@import "styles/sizing";

// Components
@import "styles/section";
@import "styles/quote";
@import "styles/video-modal";
@import "styles/video-embed";
@import "styles/footer";
@import "styles/misc";
@import "styles/integrations";
@import "styles/pricing-plan";
@import "styles/pricing-table";
@import "styles/loader";
@import "styles/range-slider";
@import "styles/richtext";
@import "styles/main";
@import "styles/simple-menu";
@import "styles/mega-menu";
@import "styles/dialog";
@import "styles/blog";

