// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-container-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

$enable-dark-mode: true !default;
$color-mode-type: data !default; // `data` or `media-query`

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Typography

$font-family-sans-serif: "Red Hat Display", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

$enable-dark-mode: true !default;
$color-mode-type: data !default; // `data` or `media-query`

$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 2.75 !default;
$h3-font-size: $font-size-base * 2 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.125 !default;
$h6-font-size: $font-size-base * 0.875 !default;
$h7-font-size: $font-size-base * 0.75 !default;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
) !default;
// scss-docs-end font-sizes

$title-font-size: $font-size-base * 1.125 !default;
$body-large-font-size: $font-size-base * 1.125 !default;
$body-small-font-size: $font-size-base * 0.875 !default;
$font-size-action:            $font-size-base * 0.9375 !default;  // 15px
$font-size-xs:                $font-size-base * .875 !default;  // 14px
$font-size-xxs:               $font-size-base * 0.8125 !default;  // 13px
$font-size-tiny:              $font-size-base * 0.6875 !default;  // 11px


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 6.25,
  21: $spacer * 21,
) !default;
// scss-docs-end spacer-variables-maps

// Color system

$black: #000000 !default;
$white: #FFFFFF !default;
$gray-200: #F8F9FD !default;
$gray-250: #ECEEF5 !default;
$gray-300: #E0E3ED !default;
$gray-400: #B3B7C6 !default;
$gray-500: #464D6B !default;

$grays: (
	"200": $gray-200,
	"250": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
);

$min-contrast-ratio: 1 !default;

$gradient: linear-gradient(151.97deg, #010D40 13.65%, #02215B 82.63%);

$dark-blue: #010D40 !default;
$dark-blue-100: #B8CCF0 !default;

$dark-blue-200: #3A4A74 !default;
$dark-blue-300: #03215B !default;
$dark-blue-400: #000A36 !default;

$light-blue: #42A6FF !default;
$light-blue-200: #F2F6FF !default;
$light-blue-300: #E3F2FF !default;
$light-blue-400: #074173 !default;

$light-green: #0CDA8F !default;
$green-200: #E6FBF0 !default;
$green-300: #D0F8E6 !default;
$green-400: #064B43 !default;

$turquoise: #01D1D1 !default;
$turquoise-200: #E1FCFC !default;
$turquoise-300: #CBF8F8!default;
$turquoise-400: #083F5F !default;

$purple-300: #D0CDF5 !default;
$purple-400: #363080 !default;

// System colours
$red: #B61620 !default;
$yellow: #FFDE51 !default;
$green: #0CDA8F !default;

// Default
$primary: $dark-blue-400 !default;
$primary-light: $light-blue !default;
$secondary: $gray-400 !default;
$success: $light-blue !default;
$info: $light-blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $dark-blue !default;

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: $dark-blue-300 !default;
$secondary-bg-subtle: $gray-200 !default;
$light-green-bg-subtle: $green-200 !default;
$light-blue-bg-subtle: $light-blue-200 !default;
$turquoise-bg-subtle: $turquoise-200 !default;
// scss-docs-end theme-bg-subtle-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $dark-blue-200 !default;
$breadcrumb-active-color:           $white !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables

$colors: (
	"primary": $primary,
	"secondary": $secondary,
	"blue": $light-blue,
	"green": $light-green,
	"turquoise": $turquoise,
	"light": $light,
	"danger": $danger,
);

$theme-colors-text: (
	"primary": $primary,
	"secondary": $secondary,
	"light": $light,
	"blue": $light-blue,
	"green": $light-green,
	"turquoise": $turquoise,
	"light_green": $light-green,
	"light_blue": $light-blue,
	"light_turquoise": $turquoise,
	"dark_blue": $light-blue,
	"dark_green": $light-green,
	"dark_turquoise": $turquoise,
	"danger": $danger,
);

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"blue": $light-blue,
	"light_blue": $light-blue,
	"dark_blue": $light-blue,
	"green": $light-green,
	"light_green": $light-green,
	"dark_green": $light-green,
	"light_turquoise": $turquoise,
	"dark_turquoise": $turquoise,
	"turquoise": $turquoise,
	"light": $light,
	"dark": $dark,
	"success": $light-green,
	"danger": $danger,
);

$theme-colors-bg-subtle: (
	"primary": $primary-bg-subtle,
	"success": $light-green,
	"secondary": $secondary-bg-subtle,
	"blue": $light,
	"light_blue": $light-blue-bg-subtle,
	"dark_blue": $primary-bg-subtle,
	"green": $light-green-bg-subtle,
	"dark_green": $primary-bg-subtle,
	"light_green": $light,
	"turquoise": $turquoise-bg-subtle,
	"light_turquoise": $light,
	"dark_turquoise": $primary-bg-subtle,
	"light": $light,
	"dark": $primary-bg-subtle,
);

// scss-docs-start theme-border-subtle-map
$theme-colors-border-subtle: (
	"primary": $primary-bg-subtle,
	"success": $light-green,
	"secondary": $secondary-bg-subtle,

	"blue": $light-blue-bg-subtle,
	"light_blue": $light-blue-bg-subtle,
	"dark_blue": $primary-bg-subtle,

	"green": $light-green-bg-subtle,
	"light_green": $light-green-bg-subtle,
	"dark_green": $primary-bg-subtle,

	"turquoise": $turquoise-bg-subtle,
	"light_turquoise": $turquoise-bg-subtle,
	"dark_turquoise": $primary-bg-subtle,

	"light": $secondary-bg-subtle,
	"dark": $primary-bg-subtle,
) !default;
// scss-docs-end theme-border-subtle-map

$theme-colors-bg-subtle-darker: (
	"primary": $primary-bg-subtle,
	"success": $light-green,
	"secondary": $gray-300,

	"blue": $light-blue-300,
	"light_blue": $light-blue-300,
	"dark_blue": $primary-bg-subtle,

	"green": $green-300,
	"light_green": $green-300,
	"dark_green": $primary-bg-subtle,

	"turquoise": $turquoise-300,
	"light_turquoise": $turquoise-300,
	"dark_turquoise": $primary-bg-subtle,

	"light": $secondary-bg-subtle,
	"dark": $primary-bg-subtle,
);

$theme-colors-bg-section: (
	"primary": $primary-bg-subtle,
	"secondary": $secondary-bg-subtle,
	"light_blue": $light-blue-300,
	"blue": $light-blue-200,
	"light_green": $green-300,
	"green": $green-200,
	"light_turquoise": $turquoise-200,
	"turquoise": $turquoise,
	"light": $light,
	"dark": $primary-bg-subtle,
);

$theme-colors-text-subtle: (
	"primary": $white,
	"secondary": $gray-500,
	"blue": $light-blue-400,
	"green": $green-400,
	"turquoise": $turquoise-400,
);

//
// Global colors
//

// scss-docs-start theme-text-dark-variables
$primary-text-emphasis-dark:        $dark-blue-400 !default;
$secondary-text-emphasis-dark:      $dark-blue-200 !default;
// scss-docs-end theme-text-dark-variables

$hr-color: $dark-blue-200;

// Body
//
// Settings for the `<body>` element.

$body-text-align:           null !default;
$body-color:                $dark-blue !default;

$body-bg:                   $white !default;

$body-secondary-color:      $gray-500 !default;
$body-secondary-bg:         $gray-200 !default;

$body-tertiary-color:       $dark-blue-400 !default;
$body-tertiary-bg:          rgba($gray-200, .15) !default;

$body-emphasis-color:       $black !default;

// Links
//
// Style anchor elements.

$link-decoration:                         none !default;
$link-hover-decoration:                   none !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start border-radius-variables
$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .75rem !default;
$border-radius-xl:            1rem !default;
$border-radius-xxl:           2rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$input-btn-padding-y: .625rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-size: $font-size-base !default;

$input-btn-padding-y-sm: .5rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-sm: $font-size-base * .875 !default;

$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-font-size-lg: $font-size-base !default;

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-size: $input-btn-font-size !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

// scss-docs-start focus-ring-variables
$focus-ring-width:      .25rem !default;
$focus-ring-opacity:    .25 !default;
$focus-ring-color:      rgba($light-blue, $focus-ring-opacity) !default;
$focus-ring-blur:       0 !default;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;
// scss-docs-end focus-ring-variables

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           var(--#{$prefix}border-radius) !default;
$btn-border-radius-sm:        var(--#{$prefix}border-radius-sm) !default;
$btn-border-radius-lg:        var(--#{$prefix}border-radius) !default;

// Badges

// scss-docs-start badge-variables
$badge-font-size:            .75rem !default;
$badge-font-weight:          $font-weight-normal !default;
$badge-color:                $white !default;
$badge-padding-y:            .5rem !default;
$badge-padding-x:            1rem !default;
$badge-border-radius:        var(--#{$prefix}border-radius-xxl) !default;
// scss-docs-end badge-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     1.25rem !default;
$accordion-padding-x:                     1.25rem !default;
$accordion-color:                         $body-color !default;
$accordion-bg:                            transparent !default;

$accordion-border-radius:                 0 !default;
$accordion-border-width:                  var(--#{$prefix}border-width) !default;
$accordion-border-color:                  $gray-300 !default;

$accordion-button-active-color:           $body-color !default;
$accordion-button-active-bg:              transparent !default;
$accordion-button-color:                  $body-color !default;
$accordion-button-bg:                     transparent !default;

$accordion-icon-width:                    1rem !default;
$accordion-icon-color:                    $body-color !default;
$accordion-icon-active-color:             $body-color !default;

$accordion-button-focus-border-color:     none !default;
$accordion-button-focus-box-shadow:       none !default;

// List group

// scss-docs-start list-group-variables
$list-group-color:                  var(--#{$prefix}body-color) !default;
$list-group-bg:                     null !default;
$list-group-border-color:           none !default;
$list-group-border-width:           0 !default;
$list-group-border-radius:          0 !default;

$list-group-item-padding-y:         $spacer * .5 !default;
$list-group-item-padding-x:         0 !default;
// fusv-disable
$list-group-item-bg-scale:          -80% !default; // Deprecated in v5.3.0
$list-group-item-color-scale:       40% !default; // Deprecated in v5.3.0
// fusv-enable

$list-group-hover-bg:               var(--#{$prefix}tertiary-bg) !default;
$list-group-active-color:           $light-blue !default;
$list-group-active-bg:              none !default;
$list-group-active-border-color:    none !default;

$list-group-disabled-color:         $gray-400 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           var(--#{$prefix}secondary-color) !default;
$list-group-action-hover-color:     var(--#{$prefix}emphasis-color) !default;

$list-group-action-active-color:    var(--#{$prefix}body-color) !default;
$list-group-action-active-bg:       none !default;
// scss-docs-end list-group-variables

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                1rem !default;
$nav-link-padding-x:                1rem !default;

// Nav pills
$nav-pills-link-color:             $dark-blue-400;

$nav-pills-border-width:           var(--#{$prefix}border-width);
$nav-pills-border-color:           $gray-300;
$nav-pills-border-radius:          var(--#{$prefix}border-radius);

// Tables

$table-cell-padding-y:        1rem;
$table-cell-padding-x:        1.5rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;

$table-cell-vertical-align:   top;

$table-color:                 $body-color;
$table-bg:                    $light;
$table-accent-bg:             transparent;

$table-th-font-weight:        null;

$table-striped-color:         $table-color;
$table-striped-bg-factor:     .05;
$table-striped-bg:            $gray-200;

$table-active-color:          $table-color;
$table-active-bg-factor:      .1;
$table-active-bg:             rgba(var(--#{$prefix}emphasis-color-rgb), $table-active-bg-factor);

$table-hover-color:           $table-color;
$table-hover-bg-factor:       .075;
$table-hover-bg:              rgba(var(--#{$prefix}emphasis-color-rgb), $table-hover-bg-factor);

$table-border-factor:         .2;
$table-border-width:          var(--#{$prefix}border-width);
$table-border-color:          var(--#{$prefix}border-color);

$table-striped-order:         even;
$table-striped-columns-order: even;

$table-group-separator-color: currentcolor;

$table-caption-color:         var(--#{$prefix}secondary-color);

$table-bg-scale:              -80%;

$zindex-nav: 						900 !default;
$zindex-top-nav: 					1100 !default;
$nav-border-bottom-width: 			1px !default;
// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer * 1.5 !default;
$card-spacer-x:                     $spacer * 1.5 !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-title-color:                  null !default;
$card-subtitle-color:               null !default;
$card-border-width:                 var(--#{$prefix}border-width) !default;
$card-border-color:                 var(--#{$prefix}border-color-translucent) !default;
$card-border-radius:                0.75rem !default;
$card-box-shadow:                   null !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       transparent !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           var(--#{$prefix}body-bg) !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// -------------------------------------------------------
// Custom variables

// Top nav
$top-nav-bg: $primary;
$top-nav-height: 60px;

// Main nav
$main-logo-width: 140px;
$main-logo-height: 25px;
$main-logo-height-mobile: 20px;
$main-nav-height: 80px;
$main-nav-bg: $dark-blue;
$main-nav-bg-mobile: $light;
$main-nav-text-color: $light;
$main-nav-text-color-mobile: $dark-blue;

// Hero
$hero-light-bg: #fff;
$hero-beige-bg: #f6f4eb;
$hero-dark-bg: #0d2140;
$hero-spacing-sm: 40px;
$hero-spacing: 60px;
$hero-spacing-lg: 100px;

// Section
$section-spacing-sm: 40px;
$section-spacing: 60px;
$section-spacing-lg: 90px;
$section-title-max-width: 740px;

// Footer
$footer-bg: $dark-blue;
$footer-nav-title-margin: 1.5rem;
$footer-title-color: $light;
$footer-title-focus-color: $light-blue;
$footer-nav-link-color: $light;
$footer-nav-link-color-hover: $light-blue;
$footer-nav-link-color-active: $light-blue;
$footer-nav-link-padding: 0.5rem;
$footer-badges-height: 50px;

// Video
$video-height-ratio: 0.5625;
