.mega-menu-section {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
}

.mega-menu-custom-content-section {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
        padding: 1.5rem;
    }
}

.mega-menu-section-common {
    @include media-breakpoint-up(xl) {
        padding: 40px 0px 40px 40px;
    }

    @include media-breakpoint-down(xl) {
        padding: 0;
    }
}

.menu-integrations-icon {
    width: 62px;
    height: 62px;
    object-fit: contain;
    object-position: center;
    padding: 11px;
}