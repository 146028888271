.dialog-overlay {
	position: fixed;
	overflow: hidden;
	z-index: $zindex-modal;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #a5a5a5;
	background-color: rgba(31,31,31,.4);
}

.dialog-close-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.dialog-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	min-width: 900px;
	max-width: 1000px;
	height: 90%;
	max-height: 780px;
	padding: 20px;
	background-color: white;
	border-radius: 5px;
}

.dialog-container-body {
	overflow-y: auto;
	max-height: 100%;
	padding: 20px;
}

.dialog-close {
	font-size: 2rem;
	color: white;
	position: fixed;
	z-index: 3;
	top: 0;
	right: 10px;
	bottom: auto;
	left: auto;
	cursor: pointer;
}
