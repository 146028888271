// Sizing
// --------------------------------------------------

$size-base: 25;

@mixin max-width($size:null) {
	.max-w-#{$size-base * $size} {
		@if $size {
			max-width: $size-base * $size + px;
		} @else {
			max-width: $size-base + px;
		}
	}
}

@include max-width(4);
@include max-width(5);
@include max-width(6);

@mixin max-height($size:null) {
	.max-h-#{$size-base * $size} {
		@if $size {
			max-height: $size-base * $size + px;
		} @else {
			max-height: $size-base + px;
		}
	}
}

@include max-height(2);

