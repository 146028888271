// Range slider

$handle-size: 2rem;
$handle-slider-radius: .75rem;

.ui-slider {
	position: relative;
	text-align: left;
	border-radius: $handle-slider-radius;
	background: $gray-250;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.2em;
	height: 1.2em;
	cursor: pointer;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: 1rem;
}
.ui-slider-horizontal .ui-slider-handle {
	outline: none;
	width: $handle-size;
	height: $handle-size;
	border-radius: 50%;
	background-color: $light-green;
	border: 6px solid $light;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
	top: 50%;
	transform: translateY(-50%);
	margin-left: -(calc($handle-size / 2));
}
.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
	background-color: $light-green;
	border-radius: $handle-slider-radius;
}
.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}
.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}
