// Breadcrumb
// --------------------------------------------------

.breadcrumb-item {
	& > a {
		text-decoration: none;
	}

	+ .breadcrumb-item {
		&::before {
			color: $dark-blue-200;
		}
	}
}
