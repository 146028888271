
.video-player {
	height: 80px;
	width: 80px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: url("data:image/svg+xml,%3Csvg%20width%3D%2280%22%20height%3D%2280%22%20viewBox%3D%220%200%2080%2080%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cmask%20id%3D%22mask0_4404_41020%22%20style%3D%22mask-type%3Aluminance%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%224%22%20y%3D%224%22%20width%3D%2272%22%20height%3D%2272%22%3E%3Cpath%20d%3D%22M39.9974%2073.3307C58.4074%2073.3307%2073.3307%2058.4074%2073.3307%2039.9974C73.3307%2021.5874%2058.4074%206.66406%2039.9974%206.66406C21.5874%206.66406%206.66406%2021.5874%206.66406%2039.9974C6.66406%2058.4074%2021.5874%2073.3307%2039.9974%2073.3307Z%22%20fill%3D%22white%22%20stroke%3D%22white%22%20stroke-width%3D%224%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M33.3281%2039.9998V28.4531L43.3281%2034.2265L53.3281%2039.9998L43.3281%2045.7731L33.3281%2051.5465V39.9998Z%22%20fill%3D%22black%22%20stroke%3D%22black%22%20stroke-width%3D%224%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fmask%3E%3Cg%20mask%3D%22url(%23mask0_4404_41020)%22%3E%3Cpath%20d%3D%22M0%200H80V80H0V0Z%22%20fill%3D%22white%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
