.simple-dropdown-menu {
    display: flex;
    padding: 1.5rem;
    background-color: $light;
    border-radius: 8px;
    width: fit-content;

    @include media-breakpoint-down(lg) {
        padding: 0px 12px 0px 12px;
        border-radius: 0px;
        width: 100%;
    }
}

.simple-dropdown-menu-list {
    list-style: none;
    width: 100%;
    padding-left: 0;
    
    // Add spacing between list items
    li:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.simple-dropdown-menu-link {
    text-decoration: none;
}

