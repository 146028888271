:root {
	--swiper-navigation-size: 3.125rem;
	--swiper-navigation-font-size: 1.125rem;
	--swiper-navigation-sm-size: 2rem;
	--swiper-navigation-top-offset: 50%;
	--swiper-navigation-sides-offset: 0;
	--swiper-navigation-color: var(--swiper-theme-color);
}

.swiper-container {
	position: relative;
}

.swiper-button-prev,
.swiper-button-next {
	margin:0;
	height: var(--swiper-navigation-size);
	width: var(--swiper-navigation-size);
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;
	border-color: var(--#{$prefix}body-color);
	color: var(--#{$prefix}body-color);
	@include media-breakpoint-up(md) {
		top: 0;
	}
}

.swiper-button-prev:after,
.swiper-button-next:after {
	font-family: 'plectoicons';
	font-size: var(--swiper-navigation-font-size);
}

.swiper-button-prev:after {
	content: "\ea17"
}

.swiper-button-next:after {
	content: "\ea19";
}

@each $color, $value in $theme-colors {
	.swiper-#{$color} {
		.swiper-button-prev:hover,
		.swiper-button-next:hover {
			color: var(--#{$prefix}#{$color});
			border-color: var(--#{$prefix}#{$color});
		}
	}
}


