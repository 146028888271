// Swiper
// --------------------------------------------------

.swiper {
	padding-bottom: 4rem;
}

.swiper-footer {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	gap: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--swiper-navigation-size);

	.swiper-button-prev,
	.swiper-button-next {
		flex-shrink: 0;
		top: 0;
	}
	.swiper-button-prev,
	.swiper-button-next,
	.swiper-pagination {
		position: relative;
	}
}

.swiper-footer-primary {
	@include media-breakpoint-up(md) {
		top: 0;
		justify-content: space-between;
		align-items: stretch;
		height: auto;
		.swiper-pagination-bullets.swiper-pagination-horizontal {
			width: auto;
		}
		.swiper-button-prev {
			left: calc(var(--swiper-navigation-size) * -1);
			align-self: center;
		}

		.swiper-button-next {
			right: calc(var(--swiper-navigation-size) * -1);
			align-self: center;
		}
		.swiper-pagination {
			align-self: flex-end;
		}
	}
}

.swiper-footer-secondary {
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
	right: auto;
	height: var(--swiper-navigation-sm-size);
	.swiper-button {
		top: auto;
		margin: 0;
		width: var(--swiper-navigation-sm-size);
		height: var(--swiper-navigation-sm-size);
		&:after {
			font-size: var(--swiper-navigation-font-size);
		}
	}
}
