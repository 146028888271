// Navbar & tabs
// --------------------------------------------------

$nav-tab-colors-bg: (
	"primary": $primary-bg-subtle,
	"secondary": $secondary-bg-subtle,
	"blue": $light-blue-bg-subtle,
	"light_blue": $light-blue-bg-subtle,
	"dark_blue": $primary-bg-subtle,
	"green": $light-green-bg-subtle,
	"dark_green": $primary-bg-subtle,
	"light_green": $light-green-bg-subtle,
	"turquoise": $turquoise-bg-subtle,
	"light_turquoise": $turquoise-bg-subtle,
	"dark_turquoise": $primary-bg-subtle,
);

$nav-tab-colors-text: (
	"primary": $dark-blue-100,
	"secondary": $dark-blue-200,
	"blue": $dark-blue-200,
	"light_blue": $dark-blue-200,
	"dark_blue": $dark-blue-100,
	"green": $dark-blue-200,
	"dark_green": $dark-blue-100,
	"light_green": $dark-blue-200,
	"turquoise": $dark-blue-200,
	"light_turquoise": $dark-blue-200,
	"dark_turquoise": $dark-blue-100,
);

.nav-tabs {
	// scss-docs-start nav-tabs-css-vars
	--#{$prefix}nav-tabs-border-width: 0;
	--#{$prefix}nav-tabs-border-color: #{$nav-tabs-border-color};
	--#{$prefix}nav-tabs-border-radius: #{$nav-tabs-border-radius};
	--#{$prefix}nav-tabs-link-hover-border-color: #{$nav-tabs-link-hover-border-color};
	--#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-link-active-color};
	--#{$prefix}nav-tabs-link-active-bg: #{$nav-tabs-link-active-bg};
	--#{$prefix}nav-tabs-link-active-border-color: #{$nav-tabs-link-active-border-color};
	// scss-docs-end nav-tabs-css-vars
}

.nav-tabs,
.tab-content {
	@each $color, $value in $theme-colors-bg-subtle-darker {
		--#{$prefix}#{$color}-nav-tabs-link-active-bg: #{$value};
	}
}

.nav-tabs,
.tab-content {
	@each $color, $value in $nav-tab-colors-text {
		--#{$prefix}#{$color}-nav-tabs-link-color: #{$value};
	}
}


.nav-link.active,
.nav-item.show .nav-link {
	color: var(--#{$prefix}nav-tabs-link-active-color);
	background-color: var(--#{$prefix}nav-tabs-link-active-bg);
	border-color: var(--#{$prefix}nav-tabs-link-active-border-color);
}

.tab-pane-content {
	padding: 3rem 2rem;
	border-radius: .5rem;
}

@each $color, $value in $nav-tab-colors-bg {
	.section-#{$color} {
		.nav-tabs {
			.nav-link.active,
			.nav-item.show .nav-link {
				background-color: var(--#{$prefix}#{$color}-nav-tabs-link-active-bg) if($enable-important-utilities, !important, null);
				color: var(--#{$prefix}nav-tabs-link-active-color) if($enable-important-utilities, !important, null);
			}
		}
		.tab-pane.active {
			.tab-pane-content {
				background-color: var(--#{$prefix}#{$color}-nav-tabs-link-active-bg) if($enable-important-utilities, !important, null);
			}
		}
		.tab-pane.active:first-child {
			.tab-pane-content {
				border-top-left-radius: 0;
			}
		}
		.tab-pane.active:last-child {
			.tab-pane-content {
				border-top-right-radius: 0;
			}
		}
		.nav-link {
			color: var(--#{$prefix}#{$color}-nav-tabs-link-color) if($enable-important-utilities, !important, null);
		}
	}
}

.nav-pills {
	// scss-docs-start nav-pills-css-vars
	--#{$prefix}nav-pills-border-width: #{$nav-pills-border-width};
	--#{$prefix}nav-pills-border-color: #{$nav-pills-border-color};
	--#{$prefix}nav-pills-border-radius: #{$nav-pills-border-radius};
	--#{$prefix}nav-pills-link-color: #{$nav-pills-link-color};
	// scss-docs-end nav-pills-css-vars

	.nav-link {
		border-style: solid;
		border-width: var(--#{$prefix}nav-pills-border-width);
		border-color: var(--#{$prefix}nav-pills-border-color);
		color: var(--#{$prefix}nav-pills-link-color);
	}
}

// Scrollbar
// --------------------------------------------------


/* width */
.scroll::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: $gray-200;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: $gray-300;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: $gray-300;
}
