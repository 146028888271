
// Integrations
// ---------------------------------

.integration-logo {
	max-width: 128px;
	max-height: 50px;
}

.integration-icon {
	display: inline-block;
	max-width: 30px;
	max-height: 30px;
}
