:root,
[data-bs-theme="light"] {
	--swiper-theme-color: #010D40;
	--swiper-pagination-color: var(--swiper-theme-color);
	--swiper-pagination-left: auto;
	--swiper-pagination-right: 0;
	--swiper-pagination-bottom: 0;
	--swiper-pagination-top: auto;
	--swiper-pagination-fraction-color: inherit;
	--swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
	--swiper-pagination-progressbar-size: 4px;
	--swiper-pagination-bullet-size: 8px;
	--swiper-pagination-bullet-width: 8px;
	--swiper-pagination-bullet-height: 8px;
	--swiper-pagination-bullet-border-radius: 50%;
	--swiper-pagination-bullet-inactive-color: #B3B7C6;
	--swiper-pagination-bullet-inactive-opacity: 1;
	--swiper-pagination-bullet-opacity: 1;
	--swiper-pagination-bullet-horizontal-gap: 4px;
	--swiper-pagination-bullet-vertical-gap: 6px;
}

[data-bs-theme="dark"] {
	--swiper-theme-color: #42A6FF;
	--swiper-pagination-color: var(--swiper-theme-color);
	--swiper-pagination-bullet-inactive-color: #B3B7C6;
}

@each $color, $value in $theme-colors {
	.swiper-#{$color} {
		.swiper-pagination-bullet-active {
			background-color: var(--#{$prefix}#{$color});
		}
	}
}

