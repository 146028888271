// Section
// --------------------------------------------------

$border-radius-section: 3.125rem;
$border-radius-section-sm: 1.25rem;

$spacing-x-section-content: 1.25rem;

$spacing-y-section-sm: 3rem;

$spacing-x-section: 4rem;
$spacing-y-section: 4rem;

[data-bs-theme="light"],
.section[data-bs-theme="light"] {
	--#{$prefix}section-secondary-text: #{$body-secondary-color};
}

[data-bs-theme="dark"],
.section[data-bs-theme="dark"] {
	--#{$prefix}section-secondary-text: #{$body-secondary-color-dark};
}


@each $color, $value in $theme-colors-bg-subtle {
	.section-#{$color} {
		color: var(--#{$prefix}section-secondary-text);
		background-color: var(--#{$prefix}#{$color}-bg-section) if($enable-important-utilities, !important, null);
		.rich-text {
			p {
				color: var(--#{$prefix}section-secondary-text);
			}
			a {
				color: $light-blue;
				cursor: pointer;
				
				&:hover {
					color: $light-blue;
					opacity: 0.8;
				}
			}
		}
	}
}

.section {
	padding: $spacing-y-section-sm 0;
	border-radius: $border-radius-section-sm;
	width: 100%;

	@include media-breakpoint-up(md) {
		padding: $spacing-y-section 0;
		border-radius: $border-radius-section;
	}

	// Add container padding when section is full-width
	&:not(.container-lg *) {
		padding-left: $spacing-x-section-content;
		padding-right: $spacing-x-section-content;

		@include media-breakpoint-up(md) {
			padding-left: $spacing-x-section;
			padding-right: $spacing-x-section;
		}
	}
}

.section-sm {
	padding: $spacing-y-section-sm 0;
	border-radius: $border-radius-section-sm;
}


.section-header {
	padding: 0 1rem;
	@include media-breakpoint-up(md) {
		padding: 0 $spacing-x-section;
	}
}

.section-content {
	padding: 0 $spacing-x-section-content;
}

.section[data-bs-theme="dark"] {
	.logo-img {
		filter: brightness(0) invert(1);
	}
}

@mixin element-layer-primary-color($color) {
  background-image: #{escape-svg(url('data:image/svg+xml,<svg width="1280" height="987" viewBox="0 0 1280 987" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M-260.842 808.068C-240.508 805.92 -184.441 828.881 -120.467 859.455C22.1117 927.595 196.075 872.795 261.614 729.002V729.002C277.471 694.214 300.556 663.203 329.334 638.033L341.978 626.974C449.209 533.186 602.765 519.342 742.278 548.167C831.437 566.589 933.284 571.676 1019.66 534.568C1283.19 421.355 1467.43 68.4405 1641.43 102.441" stroke="#{$color}" stroke-width="200"/> </svg>'))};
}

@mixin element-layer-secondary-color($color) {
  background-image: #{escape-svg(url('data:image/svg+xml,<svg width="941" height="685" viewBox="0 0 1280 987" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M-841.967 927.596C-822.847 921.849 -764.808 934.034 -697.59 952.248C-546.81 993.106 -387.694 907.197 -352.282 755.045V755.045C-343.782 718.524 -327.565 684.244 -304.72 654.509L-290.309 635.751C-205.823 525.785 -66.2903 476.014 72.1008 467.161C163.088 461.34 264.748 441.097 342.446 388.167C575.201 229.608 639.78 -103.194 937.84 -112.197" stroke="#{$color}" stroke-width="200"/> </svg>'))};
}

@each $color, $value in $theme-colors-bg-subtle-darker {
	.section-layer-#{$color} {
		@include element-layer-primary-color($value);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
}

@each $color, $value in $theme-colors-bg-subtle {
	.section-layer-secondary-#{$color} {
		@include element-layer-secondary-color($value);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
}

