// footer
// --------------------------------------------------

.footer {
	background: $footer-bg;
	margin-top: 40px;
	padding: 60px 0 10px 0;
	&-sm {
		padding: $spacer 0;
	}
}

.footer-nav-title,
.footer-nav-title > a {
	font-size: $h4-font-size;
	font-weight: $font-weight-medium;
	color: $footer-title-color;
	margin-bottom: $footer-nav-title-margin;
	&:focus {
		color: $footer-title-focus-color;
		text-decoration: none;
	}
}

.footer-nav-list {
	list-style: none;
	margin: 0 0 0px 0;
	padding: 0;
	li {
		margin: 0;
		padding-top: $footer-nav-link-padding;
		padding-bottom: $footer-nav-link-padding;
	}
}	

.footer-accordion-nav-list {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding-left: 1rem;
	}
}

.footer-language-selector {
	color: $footer-nav-link-color;
	text-decoration: none;
	&:hover {
		color: $footer-nav-link-color-hover;
	}
}

.footer-nav-item {
	margin: 0;
	padding: calc($spacer / 4) 0;
}

.footer-nav-link {
	font-size: $body-small-font-size;
	font-weight: $font-weight-light;
	color: $footer-nav-link-color;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	&:hover, &:focus, &:active {
		color: $footer-nav-link-color-hover;
	}
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}


.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2342A6FF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
 }

 .accordion-button:not(.collapsed) .footer-nav-title {
    color: $footer-nav-link-color-active;
}

// Footer bottom

.footer-bottom {
	margin-top: $spacer;
	padding-bottom: 10px;
	font-size: $body-small-font-size;
	color: $gray-700;
	@include media-breakpoint-up(sm) {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
}

.footer-bottom-nav-list {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.footer-bottom-nav-item {
	padding: 0 .25rem 0 0;
	display: inline-block;
	list-style: none;
	font-size: $body-small-font-size;
	color: $footer-nav-link-color;

	a {
		color: $footer-nav-link-color;
		text-decoration: none;
		&:hover, &:focus, &:active {
			color: $light-blue;
		}
	}

	&:after {
		content: '|';
		margin-left: .25rem;
	}
	&:last-child {
		padding-right: 0;
		&:after {
			content: none;
		}
	}
}

.footer-bottom-nav-link {
	color: $footer-nav-link-color;
	&:hover, &:focus, &:active {
		text-decoration: none;
		color: $light-blue;
	}
}

.footer-icons-wrapper {
	display: flex;
	padding-top: $footer-nav-link-padding;
	padding-left: 0.25rem;
	gap: 16px;
	align-items: center;
	.icon-img {
		display: block;
		width: 1.5rem;
		padding: 1px 1px 0 0;
		color: $light;
	}
}

.footer-app-links-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	height: 40px;
}

.footer-badges-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;
	height: $footer-badges-height;
}

// mobile footer nav
// --------------------------------------------------

@include media-breakpoint-down(sm) {

	.footer {
		padding-top: ($spacer * 2);
		.footer-nav {
			&-title	{
				font-size: $h4-font-size;
				margin-bottom: 0;
				padding: ($spacer * 0.75) 0;
			}
			&-link {
				font-size: $font-size-base;
				align-items: center;
				margin-bottom: 0;
			}
			&-list {
				margin: 0 0 $spacer;
				list-style: none !important;
				&.collapsed:not(.show) {
					display: none;
				}
			}
			&-item {
				padding: 0;
			}
			&-dropdown-link {
				display: inline-block;
				font-size: $font-size-base;
				padding: $footer-nav-link-padding 0;
			}
		}

	}

	.footer-accordion {

		border-width: 0 !important;
		.accordion-item {
			border-bottom: none !important;
		}

		.accordion-button {
			padding: 0.25rem;
			border-width: 0 !important;
			box-shadow: none !important;
		}

		.accordion-body {
			padding: 0.25rem;
		}
	}

	.footer-accordion-item {
		border-width: 0 !important;
	}

	.footer-icons-wrapper {
		display: flex;
		padding-top: $footer-nav-link-padding;
		padding-left: 0.25rem;
		gap: 16px;
		align-items: center;
		.icon-img {
			display: block;
			width: 2rem;
			padding: 1px 1px 0 0;
			color: $light;
		}
	}
}

.footer-badge {
	border-radius: 4px !important;
}

.footer-external-link-extra-margin {
	margin-right: 20px;
}

.footer-bottom-row {
	height: 40px;
}