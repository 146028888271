// Tables
// --------------------------------------------------

table {
	th {
		font-weight: $font-weight-normal;
		font-size: $body-small-font-size;
		text-transform: uppercase;
	}
	th:first-child {
		border-top-left-radius: $border-radius-lg;
	}
	th:last-child {
		border-top-right-radius: $border-radius-lg;
	}
}

.table-gradient {
	background-image: var(--#{$prefix}gradient);
	th {
		background: none;
	}
}
