//
// Cards
//

$theme-colors-card-border-color: (
	"secondary": $gray-200,
	"blue": $light-blue-200,
	"dark_blue": $dark-blue-200,
	"green": $green-200,
	"light_green": $light,
	"dark_green": $dark-blue-200,
	"turquoise": $turquoise-200,
	"dark_turquoise": $dark-blue-200,
	"light": $light,
	"dark": $dark-blue-200,
);

:root {
	@each $color, $value in $theme-colors-card-border-color {
		--#{$prefix}#{$color}-card-border-color: #{$value};
	}
	@each $color, $value in $theme-colors-bg-subtle {
		--#{$prefix}#{$color}-card-bg: #{$value};
	}
}

@each $color, $value in $theme-colors {
	.card-#{$color} {
		background-color: var(--#{$prefix}#{$color}-card-bg);
		border-color: var(--#{$prefix}#{$color}-card-border-color);
	}
}

.card-footer {
	border: none;
	padding-top: 0.5rem !important;
}

.card-body {
	padding-bottom: 0.5rem !important;
}